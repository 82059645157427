const _transparent = "transparent"
const _current = "currentColor"
const _black = "#000"
const _white = "#fff"
const _secondary = "#E48632"
const _aris = {"50":"#edfffd","100":"#c0fffa","200":"#81fff7","300":"#3afff4","400":"#00ffe8","500":"#00ffe8","600":"#00b7ac","700":"#009189","800":"#00726e","900":"#045d5a","950":"#00393a"}
const _industrial = {"50":"#fdf6ed","100":"#f8e5cd","200":"#f1c896","300":"#eaa75f","400":"#e48632","500":"#dd6a23","600":"#c34b1c","700":"#a3331a","800":"#85291b","900":"#6d231a","950":"#3e0f0a"}
const _uiBsecondary = {"50":"#222","100":"#222","200":"#222","300":"#222","400":"#222","500":"#222","600":"#222","700":"#222","800":"#222","900":"#222","950":"#222"}
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _yellow = {"50":"#fdfde9","100":"#fdfbc4","200":"#fbf38d","300":"#f9e54b","400":"#f5d31a","500":"#f1c40f","600":"#c59009","700":"#9d670b","800":"#825211","900":"#6f4314","950":"#412307"}
const _bprimary = "#111"
const _bsecondary = "#222"
const _btertiary = "#444"
const _danger = "#dc2626"
const _success = "#16a34a"
const _tbase = "#d1d5dd"
const _green = {"50":"#f0fdf5","100":"#dcfce8","200":"#bbf7d1","300":"#86efad","400":"#4ade81","500":"#22c55e","600":"#16a34a","700":"#15803c","800":"#166533","900":"#14532b","950":"#052e14"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "secondary": _secondary, "aris": _aris, "industrial": _industrial, "uiBsecondary": _uiBsecondary, "red": _red, "yellow": _yellow, "bprimary": _bprimary, "bsecondary": _bsecondary, "btertiary": _btertiary, "danger": _danger, "success": _success, "tbase": _tbase, "green": _green, "primary": _primary, "gray": _gray, "ui-bg": {"50":"#f6f6f6","100":"#e7e7e7","200":"#d1d1d1","300":"#b0b0b0","400":"#888888","500":"#6d6d6d","600":"#5d5d5d","700":"#4f4f4f","800":"#454545","900":"#3d3d3d","950":"#222222"}, "light-gray": "#c7c7c7", "dark-gray": "#6f6f6f",  }
export { config as default, _transparent, _current, _black, _white, _secondary, _aris, _industrial, _uiBsecondary, _red, _yellow, _bprimary, _bsecondary, _btertiary, _danger, _success, _tbase, _green, _primary, _gray }